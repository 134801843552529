.checkbox-exposed-collection .filter-container {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.checkbox-exposed-collection .filter-container .filter-container-item {
  width: 25%;
}
.checkbox-exposed-collection .filter-container .filter-container-item .filter-container-item-inner {
  margin: 10px;
  display: flex;
  align-items: center;
}
.checkbox-exposed-collection .filter-container .filter-container-item .filter-container-item-inner md-checkbox.md-checked .md-icon {
  background-color: #fff;
}
.checkbox-exposed-collection .filter-container .filter-container-item .filter-container-item-inner md-checkbox.md-default-theme.md-checked .md-icon:after,
.checkbox-exposed-collection .filter-container .filter-container-item .filter-container-item-inner md-checkbox.md-checked .md-icon:after {
  border-color: #000;
}
